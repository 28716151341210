import React, {useEffect, useState} from 'react';
import styled from 'styled-components';
import {connect} from 'react-redux';
import { change as rfChange } from 'redux-form';

import {FieldLang, LabelLang} from 'src/lang/components';
import inputField from 'src/components/core/form/fields/input';
import {CustomInput} from 'src/components/styled';
import { getSavedReferralCode } from 'src/screens/referral/utils';

const ReferrerStyledDiv = styled.div`
    width: 100%;
    font-size: 15px;
    
    >div:last-child {
      .form-group {
        margin-left: 30px;
      }
    }
`;

const Agreement = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  
  .checkbox {
    --background: #fff;
    --border: #D1D6EE;
    --border-hover: #BBC1E1;
    --border-active: #0052CC;
    --tick: #fff;
    position: relative;
    input,
    svg {
        width: 15px;
        height: 15px;
        display: block;
    }
    input {
        -webkit-appearance: none;
        -moz-appearance: none;
        position: relative;
        outline: none;
        background: var(--background);
        border: none;
        margin: 0;
        padding: 0;
        cursor: pointer;
        border-radius: 4px;
        transition: box-shadow .3s;
        box-shadow: inset 0 0 0 var(--s, 1px) var(--b, var(--border));
        &:hover {
            --s: 2px;
            --b: var(--border-hover);
        }
        &:checked {
            --b: var(--border-active);
        }
    }
    svg {
        pointer-events: none;
        fill: none;
        stroke-width: 2px;
        stroke-linecap: round;
        stroke-linejoin: round;
        stroke: var(--stroke, var(--border-active));
        position: absolute;
        top: 0;
        left: 0;
        width: 15px;
        height: 15px;
        transform: scale(var(--scale, 1)) translateZ(0);
    }
    &.path {
        input {
            &:checked {
                --s: 2px;
                transition-delay: .4s;
                & + svg {
                    --a: 16.1 86.12;
                    --o: 102.22;
                }
            }
        }
        svg {
            stroke-dasharray: var(--a, 86.12);
            stroke-dashoffset: var(--o, 86.12);
            transition: stroke-dasharray .6s, stroke-dashoffset .6s;
        }
    }
    &.bounce {
        --stroke: var(--tick);
        input {
            &:checked {
                --s: 11px;
                & + svg {
                    animation: bounce .4s linear forwards .2s;
                }
            }
        }
        svg {
            --scale: 0;
        }
    }
  }

  @keyframes bounce {
      50% {
          transform: scale(1.2);
      }
      75% {
          transform: scale(.9);
      }
      100% {
          transform: scale(1);
      }
  }
  
  div.dec {
    font-size: 14px;
    color: #8F92A1;
    margin-left: 15px;
    margin-top: -8px;
  }
`;

const ReferrerReferralCode = (props) => {
  const { rfChange, onUseRefferalCode, formName = 'RegisteringForm' } = props;
  const [useReferralCode, setUseReferralCode] = useState(false);
  useEffect(() => {
    const referral = getSavedReferralCode();
    if(referral) {
      setUseReferralCode(true);
      rfChange(formName, 'referralCode', referral);
    }
  }, []);

  useEffect(() => {
    onUseRefferalCode(useReferralCode);
  }, [useReferralCode]);

  return (
    <ReferrerStyledDiv {...props} className="text-center">
      <Agreement>
        <label className="checkbox bounce">
          <input
            type="checkbox"
            name="useReferralCode"
            checked={useReferralCode}
            onClick={()=> { setUseReferralCode(!useReferralCode); }}
          />
          <svg viewBox="0 0 15 15">
            <polyline points="3 8.75 7.5 12.25 12 4"></polyline>
          </svg>
        </label>
        <div className="dec">
          <LabelLang id="user.register.referrerReferralCode" />
        </div>
      </Agreement>
      {useReferralCode && (
        <CustomInput>
          <FieldLang name="referralCode" component={inputField} />
        </CustomInput>
      )}
    </ReferrerStyledDiv>
  );
};

export default connect(null, { rfChange })(ReferrerReferralCode);
