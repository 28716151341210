import React from 'react';
import {Link} from 'react-router-dom';
import styled from 'styled-components';
import LabelLang from 'src/lang/components/LabelLang';
import {URL} from 'src/resources/constants/url';

const StyledSignInLink = styled.div`
  cursor: pointer;
  font-size: 14px;
  font-weight: 400;
  
  a:active {
    font-size: inherit;
    font-weight: inherit;
  }
  
  .link {
    color: #0052CC;
    font-weight: 500;
  }

  .signUp {
    display: inline;
    color: #8F92A1;
  }
  
  .signUpAction {
    display: inline;
  }
`;

const SignInLink = ({ isPopup, onSignIn }) => {
  const content = <><div className='signUp'><LabelLang id="user.register.loginText" /></div> <div className="link signUpAction"><LabelLang id="user.register.loginButton" /></div></>;
  return (
    <StyledSignInLink>
      {
        isPopup ? (
          <div className="keepSignIn">
            <div role='link' onClick={onSignIn}>
              {content}
            </div>
          </div>
        ) : (
          <div className="keepSignIn">
            <Link to={URL.USER_SIGN_IN}>
              {content}
            </Link>
          </div>
        )
      }
    </StyledSignInLink>
  );
};

export default SignInLink;
