import React, {Component} from 'react';
import {LabelLang} from 'src/lang/components';
import styled from 'styled-components';
import ConstantApi from 'src/redux/common/list/api';
import {connect} from 'react-redux';
import MyPopover from 'src/components/styled/MyPopover';
import { checkIsUSIp } from 'src/utils/common';
import bg from './bg.svg';

const RegisterBonusStyled = styled.div`
  padding: 10px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin-left: -20px;
  margin-right: -20px;
  background-image: url(${bg});
  background-repeat: no-repeat;
  
  .desc {
    font-size: 13px;
    color: #7E7E7E;
    text-align: center;
    padding: 0 20px;
  }
  
  @media screen and (min-width: 768px) {
    border-top-left-radius: 27px;
    border-top-right-radius: 27px;
  }
`;

const TrialStyled = styled.div`
  font-size: 15px;
  color: #417505;
  text-align: center;
  font-weight: 600;
  padding-top: 10px;
`;

const getIntlKey = key => `registerBonus.${key}`;

class RegisterBonus extends Component {
  state = {
    numLeft: 0
  };

  getRegisterKycBonusLeft = () => {
    const api = new ConstantApi('api/system/user-kyc-bonus-remain');
    api.get().then(r => {
      this.setState({ numLeft: r?.Result || 0 });
    }).finally(() => {
    });
  };

  componentDidMount() {
    this.getRegisterKycBonusLeft();
  }

  render () {
    const { numLeft } = this.state;
    const { isUSIp } = this.props;
    return isUSIp && numLeft > 0 && (
      <RegisterBonusStyled>
        <div className="desc"><LabelLang id={getIntlKey('description')} /><MyPopover desc={getIntlKey('forUsOnly')} replaceIcon="fa-info-circle" /></div>
        <TrialStyled>
          <LabelLang id={getIntlKey('numLeft')} values={{ numLeft }} />
        </TrialStyled>
      </RegisterBonusStyled>
    );
  }
}

const mapState = state => ({
  isUSIp: checkIsUSIp(state),
});

export default connect(mapState, null) (RegisterBonus);
