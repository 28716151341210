import styled from 'styled-components';

export const StyledAgreement = styled.div`
  display: flex;
  flex-direction: row;
  margin: 10px 0;
  align-items: flex-start;
  
  .checkbox {
    --background: #fff;
    --border: #D1D6EE;
    --border-hover: #BBC1E1;
    --border-active: #0052CC;
    --tick: #fff;
    position: relative;
    input,
    svg {
        width: 15px;
        height: 15px;
        display: block;
    }
    input {
        -webkit-appearance: none;
        -moz-appearance: none;
        position: relative;
        outline: none;
        background: var(--background);
        border: none;
        margin: 0;
        padding: 0;
        cursor: pointer;
        border-radius: 4px;
        transition: box-shadow .3s;
        box-shadow: inset 0 0 0 var(--s, 1px) var(--b, var(--border));
        &:hover {
            --s: 2px;
            --b: var(--border-hover);
        }
        &:checked {
            --b: var(--border-active);
        }
    }
    svg {
        pointer-events: none;
        fill: none;
        stroke-width: 2px;
        stroke-linecap: round;
        stroke-linejoin: round;
        stroke: var(--stroke, var(--border-active));
        position: absolute;
        top: 0;
        left: 0;
        width: 15px;
        height: 15px;
        transform: scale(var(--scale, 1)) translateZ(0);
    }
    &.path {
        input {
            &:checked {
                --s: 2px;
                transition-delay: .4s;
                & + svg {
                    --a: 16.1 86.12;
                    --o: 102.22;
                }
            }
        }
        svg {
            stroke-dasharray: var(--a, 86.12);
            stroke-dashoffset: var(--o, 86.12);
            transition: stroke-dasharray .6s, stroke-dashoffset .6s;
        }
    }
    &.bounce {
        --stroke: var(--tick);
        input {
            &:checked {
                --s: 11px;
                & + svg {
                    animation: bounce .4s linear forwards .2s;
                }
            }
        }
        svg {
            --scale: 0;
        }
    }
  }

  @keyframes bounce {
      50% {
          transform: scale(1.2);
      }
      75% {
          transform: scale(.9);
      }
      100% {
          transform: scale(1);
      }
  }

  div.dec {
    color: #8F92A1;
    font-size: 14px;
    padding-left: 15px;
    text-align: left;
    margin-top: -2px;
  }
  
  a {
    color: #0052CC !important;
    font-size: 14px;
    line-height: 20px;
    padding-left: 5px;
    cursor: pointer;
  }
`;

export const StyledBackButtonWrap = styled.div`
  width: 40px;
  height: 40px;
  border-radius: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #F6F7FB;
  margin-left: 10px;
  margin-top: 20px;
  > img {
    max-width: 50%;
  }
`;