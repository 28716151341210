import React from 'react';
import { Button } from 'react-bootstrap';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { formValueSelector, change as rfChange } from 'redux-form';
import { withRouter } from 'react-router-dom';
import { parseDomain, ParseResultType } from 'parse-domain';
import styled from 'styled-components';
import Cookies from 'js-cookie';
import queryString from 'query-string';

import createForm from 'src/components/core/form/createForm';
import { FieldLang, HtmlLang, LabelLang, WrapperLang } from 'src/lang/components';
import reqErrorAlert from 'src/utils/errorHandler/reqErrorAlert';
import { showAlert } from 'src/screens/app/redux/action';
import inputField from 'src/components/core/form/fields/input/v2';
import { isEmail, isRequired } from 'src/components/core/form/validator';
import ga, { events } from 'src/services/ga';
import localStore from 'src/services/localStore';
import { APP, COOKIE_CLICK_ID, COOKIE_REFERRAL, COOKIE_SRC, FB_CLICK_ID } from 'src/resources/constants/app';
import { CustomInput, MaterialCustomInput, SimpleFormStyled, SimplePageStyled } from 'src/components/styled';
import SocialGroup from 'src/screens/login/components/socialGroup/v2';
import RegisterBonus from 'src/components/registerBonus';
import ReferrerReferralCode from 'src/components/referrerReferralCode/v2';
import Loading from 'src/components/loading';
import { openConfirmDialog } from 'src/components/confirmDialog/action';
import { logUserActivity, USER_ACTIONS } from 'src/components/logUserActivity';
import icRowLeft from 'src/components/mobileHeader/assets/ic_left_arrow.svg';
import BrowserDetect from 'src/services/browser-detect';
import { getSavedReferralCode, getSavedSrc } from 'src/screens/referral/utils';
import { getRecaptcha } from 'src/hocs/hocRecaptcha';
import cx from 'classnames';

// import ImageCherry from './aboveTheFold/img/img_cherry.svg';
import { checkEmailValid, checkReferralCode, register } from './action';
import { OrTextStyled } from '../login/components/loginFormWithQR/styledV2';
import SignInLink from './signInLink';
import { StyledAgreement, StyledBackButtonWrap } from './styled';
import styles from './styles.scss';
import { URL } from 'src/resources/constants/url';

const getIntlKey = name => `user.register.${name}`;
const requiredEmail = isRequired(getIntlKey('requiredEmail'));
const checkEmail = isEmail(getIntlKey('notValidEmail'));
const requiredPassword = isRequired(getIntlKey('requiredPassword'));

const passwordRegex = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[a-zA-Z]).{8,}$/;

export const validate = values => {
  const errors = {};
  if (!passwordRegex.test(values.password)) {
    errors.password = getIntlKey('passwordTooWeak');
  }
  return errors;
};

const formName = 'RegisteringForm';
const Form = createForm({
  propsReduxForm: {
    form: formName,
    validate,
    // warn
  },
});

const selectorForm = formValueSelector(formName);

export const PromotionStyled = styled.div`
  padding: 5px;
  * {
    font-size: 13px;
  }
  input {
    height: 2.5rem;
    border-radius: 20px;
    &::placeholder {
      font-size: 13px;
      color: #ccd6dc;
    }
  }
  @media screen and (max-width: 768px) {
    input {
      height: 3.5rem;
    }
    input::-webkit-input-placeholder {
      line-height:2rem;
    }
  }
`;

export const AffiliateCryptoLendBox = styled.div`
  border-radius: 10px;
  padding: 10px;
  font-size: 14px;
  font-weight: 400;
  color: #8F92A1;
  //background-image: linear-gradient(180deg,#4F546C 0%,#272A3A 100%);
  margin: 10px 0;
`;

class RegisterForm extends React.Component {
  _input;
  constructor(props) {
    super(props);
    const params = queryString.parse(props.location.search);
    const email = params?.email;
    if (email) props.rfChange(formName, 'email', email);
    this.state = {
      isNotSubmit: true,
      promotionCode: '',
      retry: 0,
      isDisabled: false,
      useReferralCode: false,
    };
  }

  // componentDidMount(prevProps, prevState) => {
  //   const { rfChange } = this.props;
  //   if (prevState.prefillEmail !== this.state.prefillEmail) {
  //     rfChange(formName, 'email', prefillEmail);
  //   }
  // }

  handleUseRefferalCode = (isUsed) => {
    this.setState({useReferralCode: isUsed});
  }

  onPreCheckSubmit = () => {
    const { promotionCode } = this.state;
    const str = promotionCode.trim();
    const isEmpty = (!str || 0 === str.length);
    if (isEmpty) {
      this.props.showAlert({
        message: getIntlKey('promotionCodeRequired'),
        type: 'danger'
      });
      this._input.focus();
    }

    return !isEmpty;
  };

  onChangeCode = e => {
    this.setState({ promotionCode: e.target.value.toString() }, () => {
    });
  };

  handleSubmit = async (e) => {
    const { email } = e;
    const { isKol, openConfirmDialog } = this.props;

    if (isKol) {
      const isCheckOk = this.onPreCheckSubmit();

      if (!isCheckOk) {
        return false;
      }
    }

    const domain = email.split('@')[1];
    const parseResult = parseDomain(domain);

    //check email valid
    const validEmail = await checkEmailValid(email);

    // Check if the domain is listed in the public suffix list
    if (parseResult.type !== ParseResultType.Listed || !validEmail) {
      return openConfirmDialog({
        message: <HtmlLang id={getIntlKey('confirmStrangeEmailMessage')} values={{email: email}} />,
        confirmTitle: 'Yes',
        cancelTitle: 'No',
        onConfirm: async () => {
          return await this.handleRegister(e);
        }
      });
    } else {
      return this.handleRegister(e);
    }
  };

  handleRegisterSocial = (res) => {
    const { onSuccess } = this.props;
    if(res?.isNewUser) {
      onSuccess(true);
    } else {
      onSuccess();
    }
  }

  handleRegister = async (e) => {
    this.setState({ isNotSubmit: false });
    const {
      email: Email,
      giftCode: GiftCode,
      password: Password,
      password: ConfirmPassword,
    } = e;
    const {
      onSuccess, additionData = null, referralCode, showAlert , register,
      checkReferralCode, logUserActivity,
    } = this.props;
    const Source = localStore.get(APP.SOURCE) || window.location.href;
    const { promotionCode = '', useReferralCode } = this.state;
    const clickId = Cookies.get(COOKIE_CLICK_ID) || '';
    const fbc = Cookies.get(FB_CLICK_ID) || null;

    if (Email && Password) {
      let user = {
        Email,
        GiftCode,
        Password,
        ConfirmPassword,
        Source,
        CouponCode: promotionCode,
        ClickID: clickId,
        FbcID: fbc,
        ...additionData
      };

      const savedReferralCode = getSavedReferralCode();
      if(savedReferralCode || useReferralCode && referralCode && referralCode.trim().length > 0) {
        const refCode = savedReferralCode || referralCode;
        try {
          const res = await checkReferralCode(refCode);
          if (res) {
            user.ReferralCode = refCode;
          } else {
            this.setState({ isNotSubmit: true });
            return showAlert({ type: 'danger', message: <LabelLang id={getIntlKey('invalidReferralCode')} /> });
          }
        } catch (e) {
          this.setState({ isNotSubmit: true });
          return reqErrorAlert(e, { message: <LabelLang id={getIntlKey('registerFailed')} /> });
        }
      }

      const params = queryString.parse(this.props.location.search);

      if(params?.sub1) {
        user.Sub1 = params?.sub1;
      }

      if(params?.sub2) {
        user.Sub2 = params?.sub2;
      }

      // //check email valid
      // const res = await checkEmailValid(Email);
      //
      // if(!res) {
      //   this.setState({ isNotSubmit: true });
      //   throw new SubmissionError({ email: <LabelLang id={getIntlKey('invalidEmail')} />, _error: 'Register failed!' });
      // }
      const captcha = await getRecaptcha('register');
      return register(user, captcha)
        .then(res => {
          const src = getSavedSrc();
          let action = events.action.signUp;
          let userAction = USER_ACTIONS.USER_SIGN_UP.signUp;
          try {
            if (src) {
              action = src === 'fb' ? events.action.signUpFacebook : src === 'tw' ? events.action.signUpTwitter : events.action.signUp;
              userAction = src === 'fb' ? USER_ACTIONS.USER_SIGN_UP.signUpFacebook : src === 'tw' ? USER_ACTIONS.USER_SIGN_UP.signUpTwitter : USER_ACTIONS.USER_SIGN_UP.signUp;
            } else {
              action = Source.includes(URL.INVEST) ? events.action.signUpInvest : Source.includes(URL.CRYPTO_LOAN) ? events.action.signUpBorrow : events.action.signUp;
              userAction = Source.includes(URL.INVEST) ? USER_ACTIONS.USER_SIGN_UP.signUpInvest : Source.includes(URL.CRYPTO_LOAN) ? USER_ACTIONS.USER_SIGN_UP.signUpBorrow : USER_ACTIONS.USER_SIGN_UP.signUp;
            }
            ga(events.category.signUp, action, Email);
            logUserActivity(userAction);
          } catch (err) {
            ga(events.category.signUp, action, Email);
            logUserActivity(userAction);
          }

          Cookies.remove(COOKIE_REFERRAL);
          Cookies.remove(COOKIE_SRC);
          Cookies.remove(COOKIE_CLICK_ID);

          if (typeof onSuccess === 'function') {
            onSuccess(true);
          }
        })
        .catch(e => {
          console.log("🚀 ~ file: registerFormV2.js ~ line 291 ~ RegisterForm ~ handleRegister= ~ e", e)
          const {retry} = this.state;
          if(e?.code === -1076 && retry < 1) {
            const self = this;
            return this.setState({retry: retry + 1}, ()=> {
              return getRecaptcha('regiter').then(token => {
                self.setState({captchaValue:token}, () => { return register(user, token);});
              });
            });
          }

          // if(e?.code === -1005) {
          //   throw new SubmissionError({ email: <LabelLang id="error.serverCode.registerWithExistEmail" />, _error: 'Register failed!' });
          // }

          reqErrorAlert(e, { message: <LabelLang id={getIntlKey('registerFailed')} /> });
        })
        .finally(() => {
          this.setState({ isNotSubmit: true });
        });
    }
  }

  render() {
    const {
      onSuccess, onRequireOTP, isKol, referralCode, actionButtonText, location,
      startDate, duration, showPromotionCountdown, showRegisterBonus = true,
      isPopup, modalActions, onSignIn, theme, affiliateInfo
    } = this.props;
    const { isNotSubmit, promotionCode, isDisabled, useReferralCode } = this.state;
    const params = queryString.parse(location.search);
    const isCryptoLend = [URL.CRYPTO_LEND_TO_EARN].includes(location.pathname);
    const isBorrow = [URL.USER_SIGN_UP_BORROW].includes(location.pathname);

    const isDark = params.v === 'c';

    const style = {
      '--text-primary': theme === 'dark' ? 'white' : '#171717',
      '--text-secondary': theme === 'dark' ? '#8F92A1' : '#8F92A1',
      '--background': theme === 'dark' ? '#2E3041' : 'white',
      '--input-background': theme === 'dark' ? 'rgba(143, 146, 161, 0.05)' : '#F9F9FA',
      '--border': theme === 'dark' ? 'transparent' : '#ededed',
    };

    return (
      <SimplePageStyled style={style} className={styles.registerForm}>
        {BrowserDetect.isMobile && isPopup && (
          <StyledBackButtonWrap onClick={() => modalActions.close()}>
            <img src={icRowLeft} alt="myconstant.com" />
          </StyledBackButtonWrap>
        )}
        <div className="welcomeTitle bold signup">
          <h2><LabelLang id={getIntlKey('popupTitle')} /></h2>
          <SignInLink isPopup={isPopup} onSignIn={onSignIn} />
        </div>
        <div className="form">
          {/* <img alt="" src={ImageCherry} className={styles.imgCherry} /> */}
          {showRegisterBonus && <RegisterBonus />}
          {isKol && (
            <WrapperLang>
              {ts => (
                <PromotionStyled>
                  <CustomInput label={null}>
                    <input
                      ref={c => (this._input = c)}
                      placeholder={ts(getIntlKey('promotionCode'))}
                      value={promotionCode}
                      onChange={this.onChangeCode}
                      maxLength="20"
                    />
                  </CustomInput>
                </PromotionStyled>
              )}
            </WrapperLang>
          )}
          <SocialGroup
            theme={theme}
            onSuccess={this.handleRegisterSocial}
            onRequireOTP={onRequireOTP}
            onPreCheckSubmit={isKol && this.onPreCheckSubmit}
            useReferralCode={useReferralCode}
            referralCode={referralCode}
            authDiscord={this.props?.data?.authDiscord}
          />
          <OrTextStyled><span>OR</span></OrTextStyled>
          <SimpleFormStyled>
            <Form onSubmit={this.handleSubmit}>
              <MaterialCustomInput
                primary={style['--text-primary']}
                secondary={style['--text-secondary']}
                background={style['--background']}
                inputBackground={style['--input-background']}
                border={style['--border']}
              >
                <FieldLang
                  id="rg_email"
                  name="email"
                  component={inputField}
                  validate={[requiredEmail, checkEmail]}
                  type="email"
                  placeholder="user.login.email"
                  zIndex={1055}
                />
              </MaterialCustomInput>
              <MaterialCustomInput
                primary={style['--text-primary']}
                secondary={style['--text-secondary']}
                background={style['--background']}
                inputBackground={style['--input-background']}
                border={style['--border']}
              >
                <FieldLang
                  name="password"
                  component={inputField}
                  validate={[requiredPassword]}
                  type="password"
                  placeholder="user.login.password"
                  isErrorOverlay={false}
                />
              </MaterialCustomInput>
              <ReferrerReferralCode onUseRefferalCode={this.handleUseRefferalCode} formName={formName} />
              {
                (isCryptoLend || isBorrow) && (
                  <AffiliateCryptoLendBox style={{background: isDark ? '#4F546C' : '#F9F9FA'}}>
                    {isCryptoLend && `You’re signing up using an invitation code. Earn 10 USDT sign-up bonus when you deposit $100 worth of USDT or USDC and hold for 30 consecutive days. T&Cs apply.`}
                    {isBorrow && `Sign up using the invitation code above to earn instantly a 10 USDT bonus when you borrow at least $500 in a crypto-backed loan(s).`}
                  </AffiliateCryptoLendBox>
                )
              }
              <StyledAgreement>
                <label className="checkbox bounce">
                  <input
                    type="checkbox"
                    name="agreement"
                    checked={!isDisabled}
                    validate={[requiredPassword]}
                    onClick={()=> { this.setState({isDisabled: !isDisabled}); }}
                  />
                  <svg viewBox="0 0 15 15">
                    <polyline points="3 8.75 7.5 12.25 12 4"></polyline>
                  </svg>
                </label>
                <div className="dec">
                  <LabelLang id="user.register.agreementText" />
                  <a
                    className="link"
                    target="_blank"
                    onClick={()=>{
                      window.open('/agreements', '_blank');
                    }}
                  >
                    <LabelLang id="user.register.agreementTextLink" />
                  </a>
                </div>
              </StyledAgreement>
              <div className={cx(styles.signUpButtonWrapper, 'd-grid')}>
                <Button disabled={isDisabled || true} type="submit" variant="primary" block>{!isNotSubmit ? <Loading dark={false} /> : actionButtonText ? actionButtonText : <LabelLang id={getIntlKey('registerButton')} />}</Button>
              </div>
            </Form>
          </SimpleFormStyled>
        </div>
      </SimplePageStyled>
    );
  }
}

const mapState = state => ({
  email: selectorForm(state, 'email'),
  password: selectorForm(state, 'password'),
  referralCode: selectorForm(state, 'referralCode'),
});

const mapDispatch = {
  register,
  checkReferralCode,
  showAlert,
  openConfirmDialog,
  logUserActivity,
  rfChange,
};

export default compose(
  connect(mapState, mapDispatch),
  withRouter,
)(RegisterForm);
