import React, { useState } from 'react';
import { withRouter } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import Cookies from 'js-cookie';

import { URL } from 'src/resources/constants/url';
import RegisterForm from 'src/screens/register/registerFormV2';
import LoginFormWithQR from 'src/screens/login/components/loginFormWithQR/v2';
import { showAlert } from 'src/screens/app/redux/action';
import { getProfileAction } from 'src/screens/auth/redux/action';
import { MAX_AMOUNT_REQUIRE_2FA } from 'src/resources/constants/app';
import TwoFactorForm from 'src/screens/me/pages/Security/TwoFactor/twoFactorForm';
import styled from 'styled-components';
import { sendFbCompleteRegistrationEvent, postData } from 'src/services/facebookConversion';
import { logUserActivity, USER_ACTIONS } from '../logUserActivity';

export const VIEWS = {
  LOGIN: 'LOGIN',
  REGISTER: 'REGISTER',
  FORGET_PASSWORD: 'FORGET_PASSWORD'
};

const URL_NOT_REDIRECT = [
  URL.PRO_LENDING,
  URL.CRYPTO_LOAN,
  URL.WITHDRAW,
  URL.TRANSFER,
  URL.INVEST,
  URL.LOAN_ORIGINATORS,
  URL.DEPOSIT_FIAT_PLAID_CONFIRM,
  URL.MCT_MASCOT,
  URL.MASCOT_NFT_DETAIL,
  URL.UPDATE_ADDRESS
];

const LoginPopupStyled = styled.div`
  .form {
    background: none;
    border: none;
    box-shadow: none;
    border-radius: unset;
    padding: 0px 20px;
  }
  .welcomeTitle {
    padding: 5px 20px 0;
  }
`;

const Login = (props) => {
  const { onSuccess, history, location, modalActions, data, actionButtonText } = props;
  const { view: defaultView = null, ...additionData } = data || {};
  const dispatch = useDispatch();

  const [view, setView] = useState(defaultView || VIEWS.LOGIN);
  const [isRequireOTP, setIsRequireOTP] = useState(false);

  const onSuccessHandler = (isRegister) => {
    dispatch(getProfileAction()).then((profile) => {
      const { TwoFAOn, constantBalance } = profile;
      const isRedirect = URL_NOT_REDIRECT.findIndex(url => location.pathname.includes(url)) < 0;
      if (!TwoFAOn && constantBalance > MAX_AMOUNT_REQUIRE_2FA && isRedirect) {
        dispatch(showAlert({
          message: 'user.login.twoFaRequired',
          type: 'warning',
          timeOut: 5000,
        }));
        history?.push(URL.ME_MYINFORMATION + '?enable2fa=true');
      } else {
        if (typeof onSuccess === 'function') {
          onSuccess(isRegister);
        }

        if (isRegister) {
          setTimeout(() => {
            if (APP_ENV.isLive) {
              if (Cookies.get('_fbc')) { // User from facebook
                const fbData = { raw: postData('CompleteRegistration', false), hashed: postData('CompleteRegistration') };
                dispatch(logUserActivity(USER_ACTIONS.USER_SIGN_UP.signUpComplete, JSON.stringify(fbData)));
                sendFbCompleteRegistrationEvent();
              } else {
                logUserActivity(USER_ACTIONS.USER_SIGN_UP.signUpComplete);
              }
              // fbq('track', 'CompleteRegistration', { action_source: 'website', event_source_url: window.location.href, client_user_agent: navigator.userAgent });
            }
            history.push(`${URL.THANK_YOU}?time=${new Date().getTime()}`);
          }, 0);
        }
      }
    });

    modalActions?.close();
  };

  const onChangeView = view => {
    if (view === VIEWS.FORGET_PASSWORD) {
      history?.push(URL.USER_FORGET_PASSWORD);
      modalActions?.close();
    }

    if (Object.values(VIEWS).includes(view)) {
      setView(view);
    }
  };

  const goToSignUp = () => {
    onChangeView(VIEWS.REGISTER);
  };

  const goToSignIn = () => {
    onChangeView(VIEWS.LOGIN);
  };

  const goToForgetPassword = () => {
    onChangeView(VIEWS.FORGET_PASSWORD);
  };

  const handleRequiredOTP = (require) => {
    setIsRequireOTP(require);
  };

  return (
    <LoginPopupStyled>
      {isRequireOTP ? (
        <TwoFactorForm onRequireOTP={handleRequiredOTP} activeInput={0}/>
      ) : (
        view === VIEWS.LOGIN ? (
          <LoginFormWithQR {...props} additionData={additionData} onSuccess={onSuccessHandler} onSignup={() => goToSignUp()} onForgetPassword={() => goToForgetPassword()} onRequireOTP={handleRequiredOTP} />
        ) : (
          <RegisterForm {...props} additionData={additionData} onSuccess={onSuccessHandler} onSignIn={() => goToSignIn()} onRequireOTP={handleRequiredOTP} actionButtonText={actionButtonText} />
        )
      )}
    </LoginPopupStyled>
  );
};

export default withRouter(Login);
